import React, { useState } from 'react'
import { graphql } from 'react-apollo'

import AutoComplete from 'Components/AutoComplete'
import { LISTAR_CLIENTES } from 'Graphql/cliente'

const SelectClienteNF = ({ onChange, clientes: { loading, listarClientes: clientes = [] } = {}, ...props }) => {
  const [results, setResults] = useState([])

  const handleSearch = async (e, v) => {
    onChange(e, v)

    const { newValue, method } = v

    if (method !== 'type') {
      return
    }

    setResults([])

    if (newValue === '' || newValue === undefined) {
      setResults([])
      return
    }

    try {
      const filtered = clientes.filter(c => c && c.nome.toLowerCase().indexOf(newValue.toLowerCase()) > -1)
      const instituicoes = filtered.map(i => ({
        ...i,
        title: i.nome,
        description: i.nome
      }))

      setResults(instituicoes.slice(0, 5))
    } catch (e) {
      setResults([])
    }
  }

  return (
    <AutoComplete onChange={handleSearch} {...props} options={results} loading={loading} />
  )
}

export default graphql(LISTAR_CLIENTES, { name: 'clientes' })(SelectClienteNF)
