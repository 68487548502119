import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";

const DeltaPie = ({ data, header, ...props }) => {
  function colorSet(entry, index) {
    if (entry.error && entry.error === true) {
      return "#FF5032";
    }
    if (entry.warn && entry.warn === true) {
      return "#FEB832";
    }
    return colors[index % colors.length];
  }

  const colors = [
    "#1F9C93",
    "#2F58A9",
    "#66C9C1",
    "#7493D0",
    "#FFD482",
    "#FFB882",
  ];

  return (
    <Grid item xs={12}>
      <Typography variant="body1">
        {header && header !== "" ? header : "Título do Gráfico"}
      </Typography>
      <ResponsiveContainer width="100%" height={230}>
        <PieChart height={200}>
          <Pie
            data={data.sort((a, b) => a.value - b.value)}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            labelLine
            innerRadius={50}
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colorSet(entry, index)} />
            ))}
            )}
          </Pie>
          <Tooltip />
          <Legend
            verticalAlign="middle"
            layout="vertical"
            align="left"
            height={36}
          />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default DeltaPie;
