import React from 'react'
import { ListItem, ListItemText, ListItemSecondaryAction, Tooltip, IconButton } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const Item = ({ cliente: { nome, codigo }, removerCliente, divider, history }) => (
  <ListItem divider={divider}>
    <ListItemText primary={nome} secondary={`Código: ${codigo.toString().padStart(3, '0')}`} />
    <ListItemSecondaryAction>
      <Tooltip title='Editar'>
        <IconButton edge='end' aria-label='Editar' onClick={() => history.push(`/interno/clientes/editar/${codigo}`)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Excluir'>
        <IconButton edge='end' aria-label='Excluir' onClick={() => removerCliente(codigo)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  </ListItem>
)

export default Item
