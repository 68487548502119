import React from "react";
import {
  Grid,
  Paper,
  makeStyles,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import { compose, graphql } from "react-apollo";
import { GET_RESUMO_DASHBOARD } from "Graphql/notas";

import TabelaPrincipal from "./Components/TabelaPrincipal";
import PieStatusNotas from "./Components/Graficos/PieStatusNotas";
import PieTipoEnvio from "./Components/Graficos/PieTipoEnvio";
import PiePlataforma from "./Components/Graficos/PiePlataforma";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 10,
  },
}));

const DashboardInterno = ({ query, ...props }) => {
  const classes = useStyles();
  const hasError = query.error !== undefined;

  React.useEffect(() => {
    if (query.error) {
      console.log(query.error.message);
    }
  }, [hasError, query, props]);

  if (!query.dashboardQueryTabelaPrincipal) {
    query.dashboardQueryTabelaPrincipal = [];
  }

  return (
    <Grid container component={Paper} className={classes.root}>
      <Typography variant="h5">Dashboard interno</Typography>
      <Grid item xs={12}>
        {query.loading && <LinearProgress />}
      </Grid>
      {!query.loading && query.dashboardQueryTabelaPrincipal.length > 0 && (
        <>
          <Grid
            container
            className={classes.root}
            component={Paper}
            item
            xs={4}
          >
            <PieStatusNotas notas={query.dashboardQueryTabelaPrincipal} />
          </Grid>
          <Grid
            container
            className={classes.root}
            component={Paper}
            item
            xs={4}
          >
            <PieTipoEnvio notas={query.dashboardQueryTabelaPrincipal} />
          </Grid>
          <Grid
            container
            className={classes.root}
            component={Paper}
            item
            xs={4}
          >
            <PiePlataforma notas={query.dashboardQueryTabelaPrincipal} />
          </Grid>

          <Grid
            container
            className={classes.root}
            component={Paper}
            item
            xs={12}
          >
            <Typography variant="h6">Tabela principal:</Typography>
            <TabelaPrincipal notas={query.dashboardQueryTabelaPrincipal} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default compose(graphql(GET_RESUMO_DASHBOARD, { name: "query" }))(
  DashboardInterno
);
