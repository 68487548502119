import React from 'react'
import { withFormik, Form } from 'formik'
import { compose, graphql } from 'react-apollo'
import { withSnackbar } from 'notistack'
import * as Yup from 'yup'

import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  LinearProgress
} from '@material-ui/core'

import useStyles from './styles'
import SelectCliente from './SelectCliente'
import { LISTAR_CLIENTES, UPSERT_CLIENTE, GET_CLIENTE } from 'Graphql/cliente'

const AdicionarCliente = ({
  handleSubmit,
  handleChange,
  setFieldValue,
  isSubmitting,
  values,
  errors,
  cliente: { listarCliente: cliente, loading, error } = {},
  ...props
}) => {
  const classes = useStyles()
  const applyProps = (id) => ({
    id: id,
    name: id,
    value: values[id],
    onChange: handleChange,
    error: !!errors[id],
    helperText: errors[id]
  })

  if (loading) return <LinearProgress />

  return (
    <Form>
      <Grid container component={Paper} className={classes.root}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6'>{cliente ? 'Editar' : 'Adicionar'} Cliente</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectCliente label='Cliente' value={values.clienteNome} error={!!errors['cliente']} helperText={errors['cliente']} onSelected={(v) => setFieldValue('cliente', v)} onChange={(e, { newValue }) => setFieldValue('clienteNome', newValue)} />
          </Grid>
          <Grid item xs={12}>
            <TextField label='Alias' placeholder='Ex: nome-do-colegio' {...applyProps('alias')} />
          </Grid>
          <Grid item xs={12}>
            <TextField label='API Consulta' placeholder='http://website.com.br/consultaNotas' {...applyProps('apiConsulta')} />
          </Grid>
          <Grid item xs={12}>
            <TextField label='API Escrita' placeholder='http://website.com.br/salvaNotas' {...applyProps('apiEscrita')} />
          </Grid>
          <Grid item xs={12}>
            <TextField label='API Lista Empresas' placeholder='http://website.com.br/listaEmpresas' {...applyProps('apiEmpresas')} />
          </Grid>
          <Grid item xs={12}>
            <TextField label='API Login' placeholder='http://website.com.br/login' {...applyProps('apiLogin')} />
          </Grid>
          <Grid item container justify='flex-end'>
            <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  )
}

export default compose(
  withSnackbar,
  graphql(GET_CLIENTE, {
    name: 'cliente',
    skip: ({ match }) => !match.params.codigo,
    options: ({ match }) => ({
      variables: {
        codigo: parseInt(match.params.codigo)
      }
    })
  }),
  graphql(UPSERT_CLIENTE, { name: 'adicionarClienteMutation' }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({
      cliente: {
        listarCliente: {
          nome,
          codigo,
          alias,
          apiConsulta,
          apiEscrita,
          apiEmpresas,
          apiLogin
        } = {}
      } = {}
    }) => ({
      clienteNome: nome || '',
      cliente: codigo ? ({ codigo, nome }) : null,
      alias: alias || '',
      apiConsulta: apiConsulta || '',
      apiEscrita: apiEscrita || '',
      apiEmpresas: apiEmpresas || '',
      apiLogin: apiLogin || ''
    }),
    validationSchema: Yup.object().shape({
      cliente: Yup.object().nullable().required('Campo obrigatório'),
      apiConsulta: Yup.string().url('URL inválida').required('Campo obrigatório'),
      apiEscrita: Yup.string().url('URL inválida').required('Campo obrigatório'),
      apiEmpresas: Yup.string().url('URL inválida').required('Campo obrigatório'),
      apiLogin: Yup.string().nullable().url('URL inválida')
    }),
    validateOnChange: false,
    handleSubmit: async ({
      cliente,
      clienteNome,
      ...values
    }, { setSubmitting, props: { adicionarClienteMutation, enqueueSnackbar, history } }) => {
      const input = {
        codigo: cliente.codigo,
        nome: cliente.nome,
        ...values
      }

      try {
        const response = await adicionarClienteMutation({
          variables: { cliente: input },
          refetchQueries: [{ query: LISTAR_CLIENTES }]
        })

        if (response.data && response.data.adicionarCliente) {
          enqueueSnackbar('Cliente adicionado com sucesso!', { variant: 'success' })
          history.push('/interno/clientes')
        }
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }

      setSubmitting(false)
    }
  })
)(AdicionarCliente)
