import React, { useContext } from 'react'
import { Route, Switch, Link } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { useStylesSidebar as useStyles } from './styles'
import Profile from './Profile'

const SidebarContext = React.createContext({})

const SidebarItem = ({ route, icon, text, divider }) => {
  const context = useContext(SidebarContext)

  const Item = ({ disabled, divider }) => (
    <ListItem button divider={divider} disabled={disabled} component={Link} to={route} onClick={() => context.setOpen(false)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )

  return (
    <Switch>
      <Route path={route} exact >
        <Item disabled divider={divider} />
      </Route>
      <Route>
        <Item divider={divider} />
      </Route>
    </Switch>
  )
}

const Sidebar = ({ routes }) => {
  const theme = useTheme()
  const classes = useStyles()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const context = useContext(SidebarContext)

  return (
    <Drawer
      className={classes.drawer}
      variant={mobile ? 'temporary' : 'permanent'}
      classes={{
        paper: classes.drawerPaper
      }}
      open={context.isOpen}
      onClose={() => context.setOpen(false)}
    >
      {mobile ? null : <div className={classes.toolbar} />}
      <Profile />
      <List>
        {
          routes
            .map(r => {
              if (!r.icon && !r.title) return null
              return (<SidebarItem key={r.path} route={r.path} icon={r.icon} text={r.title} divider={r.divider} />)
            })
            .filter(f => f)
        }
      </List>
    </Drawer>
  )
}

export {
  Sidebar as default,
  SidebarContext
}
