import gql from "graphql-tag";

export const CANCELAR_NOTA = gql`
  mutation CancelarNota($id: ID!) {
    cancelarNota(id: $id)
  }
`;
// Queries
export const GET_RESUMO_DASHBOARD = gql`
  {
    dashboardQueryTabelaPrincipal {
      codigoCliente
      nomeCliente
      idEmpresa
      razaoSocialEmpresa
      plataformaEmpresa
      tipoEnvioEmpresa
      ultimaAutorizacao
      ultimoRps
      qtdNotasStatusAutorizadaNoMesDeCompetencia
      qtdNotasStatusEnviandoNoMesDeCompetencia
      qtdNotasStatusNegadasNoMesDeCompetencia
      Reportar
      Simples
      aliquota
      issEmpresa
      valorTotalNotasAutorizadasNoMesDeCompetencia
    }
  }
`;
