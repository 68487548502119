import React from 'react'
import Autosuggest from 'react-autosuggest'

import { Paper, TextField, MenuItem, InputAdornment, CircularProgress } from '@material-ui/core'

import useStyles from './styles'

const InputComponent = ({ ...props }) => {
  return (
    <TextField {...props} />
  )
}

const SuggestionsContainer = ({ containerProps, children, query }) => (
  <Paper {...containerProps} square>
    {children}
  </Paper>
)

const SuggestionItem = (item, { query, isHighlighted }) => {
  return (
    <MenuItem selected={isHighlighted} component='div' style={{ whiteSpace: 'normal' }}>
      {item.codigo.toString().padStart(3, '0')} - {item.title}
    </MenuItem>
  )
}

const AutoComplete = ({ onSelected, value, options, loading, ...props }) => {
  const classes = useStyles()

  return (
    <Autosuggest
      renderInputComponent={InputComponent}
      inputProps={{
        ...props,
        value: value || '',
        InputProps: {
          ...props.InputProps,
          endAdornment: !loading ? null : (
            <InputAdornment position='end'>
              <CircularProgress size={20} />
            </InputAdornment>)
        }
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      renderSuggestionsContainer={SuggestionsContainer}
      renderSuggestion={SuggestionItem}
      onSuggestionsClearRequested={() => null}
      onSuggestionsFetchRequested={() => null}
      onSuggestionSelected={(event, { suggestion }) => onSelected(suggestion)}
      suggestions={options}
      getSuggestionValue={(item) => `${item.codigo} - ${item.title}`}
    />
  )
}

export default AutoComplete
