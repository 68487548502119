import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'

const socketUri = () => {
  if (process.env.NODE_ENV === 'development') { return 'ws://localhost:4000/graphql' }
  return `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/graphql`
}

const serverUri = () => {
  if (process.env.NODE_ENV === 'development') { return 'http://localhost:4000/graphql' }
  return `${window.location.protocol}//${window.location.host}/graphql`
}

let token = window.localStorage.getItem('AUTH_TOKEN')

const authLink = setContext((_, { headers }) => {
  token = window.localStorage.getItem('AUTH_TOKEN')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const wsLink = new WebSocketLink({
  uri: socketUri(),
  options: {
    reconnect: true,
    connectionParams: { token }
  }
})

const uploadLink = createUploadLink({ uri: serverUri(), includeExtensions: true })

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(uploadLink)
)

const client = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache()
})

export default client
