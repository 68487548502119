import gql from 'graphql-tag'

export const CONSULTAR_CEP = gql`
  query ConsultaCEP($cep: String) {
    consultaCEP(cep: $cep) {
      estado
      cidade
      codigoIbgeCidade
      bairro
      logradouro
    }
  }
`

export const CONSULTAR_RECEITAWS = gql`
  query BuscarEmpresaReceitaFederal($cnpj: String!) {
    buscarEmpresaReceitaFederal(cnpj: $cnpj) {
      cnpj
      nome
      fantasia
      email
      telefone
      cep
      uf
      municipio
      bairro
      numero
      logradouro
      complemento
    }
  }
`
