/* global fetch */
import React, { useState } from 'react'

import AutoComplete from 'Components/AutoComplete'

const SelectCliente = ({ onChange, ...props }) => {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  const handleSearch = async (e, v) => {
    onChange(e, v)

    const { newValue, method } = v
    // if (method === 'click' || method === 'enter') {
    //   setResults([])
    // }

    if (method !== 'type') {
      return
    }

    setLoading(true)
    setResults([])

    if (newValue === '' || newValue === undefined) {
      setLoading(false)
      setResults([])
      return
    }

    try {
      const resp = await fetch(`https://deltasge.com.br/app_react/index.php?pesquisa=${encodeURI(newValue)}`).then(r => r.json())
      const instituicoes = resp.dados.map(i => ({
        ...i,
        title: i.nome,
        description: i.endereco + ' - ' + i.cidade + ' - ' + i.estado
      }))
      setResults(instituicoes.slice(0, 5))
    } catch (e) {
      setResults([])
    }

    setLoading(false)
  }

  return (
    <AutoComplete onChange={handleSearch} {...props} options={results} loading={loading} />
  )
}

export default SelectCliente
