import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { compose, graphql } from 'react-apollo'
import { ENVIAR_NOTA_TESTE } from '../../../Graphql/empresa'
import { withSnackbar } from 'notistack'
import ProgressButton from '../../../Components/Buttons/ProgressButton'

const ConfirmaHomologacao = ({ email, open, handleClose, empresaId, enviarNotaTesteMutation, enqueueSnackbar }) => {
  const [loading, setLoading] = useState(false)

  const enviarNotaTeste = async () => {
    try {
      setLoading(true)
      const { data: { enviarNotaTeste: dados } } = await enviarNotaTesteMutation({ variables: { empresaId } })

      if (dados) {
        enqueueSnackbar('A nota para teste foi enviada para prefeitura!', { variant: 'success' })
      } else {
        enqueueSnackbar('Não foi possível emitir a nota teste!', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }

    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        Confirmar teste de homologação
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Você permite que seja emitida uma nota teste <strong>EM PRODUÇÃO</strong> com o valor de R$ 1,00? <strong>ATENÇÃO:</strong> O resultado do teste de homologação pode demorar algumas horas e será enviado para o e-mail: <strong>{email}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
            Cancelar
        </Button>
        <ProgressButton
          color='primary'
          label='Sim'
          loading={loading}
          onClick={() => enviarNotaTeste()}
        />
      </DialogActions>
    </Dialog>
  )
}

export default compose(
  graphql(ENVIAR_NOTA_TESTE, { name: 'enviarNotaTesteMutation' }),
  withSnackbar
)(ConfirmaHomologacao)
