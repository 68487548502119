import React, { useEffect } from 'react'
import { Form, withFormik } from 'formik'
import { compose, graphql, withApollo } from 'react-apollo'
import { withSnackbar } from 'notistack'
import * as Yup from 'yup'

import { CssBaseline, TextField, Typography, Container, Grid } from '@material-ui/core'

import logo from 'Assets/images/logo.png'
import ProgressButton from 'Components/Buttons/ProgressButton'
import Password from 'Components/Inputs/Password'
import { LISTAR_CLIENTE } from 'Graphql/cliente'
import { LOGIN_CLIENTE } from 'Graphql/usuario'

import SelectClientesNF from './SelectClientesNF'
import { login as useStyles } from './styles'

const Login = ({ match, values, client, cliente = {}, errors, handleChange, setFieldValue }) => {
  const classes = useStyles()

  useEffect(() => {
    client.resetStore()
    localStorage.clear()
  }, [client])

  const applyProps = (id) => ({
    id: id,
    name: id,
    value: values[id],
    onChange: handleChange,
    error: !!errors[id],
    helperText: errors[id]
  })

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} style={{ maxWidth: '300px' }} alt='Delta Software' />
        <Typography component='h1' variant='h5'>
          Delta NFS-e
        </Typography>
        <Grid component={Form} className={classes.form} container spacing={3} justify='center'>
          {cliente.listarCliente && (
            <Grid item xs={12}>
              <TextField disabled value={cliente.listarCliente.nome} />
            </Grid>
          )}
          {(!cliente.loading && !cliente.listarCliente) && (
            <Grid item xs={12}>
              <SelectClientesNF fullWidth label='Cliente' value={values.clienteNome} error={!!errors['clienteId']} helperText={errors['clienteId']} onSelected={(v) => { setFieldValue('clienteId', parseInt(v.id)) }} onChange={(e, { newValue }) => setFieldValue('clienteNome', newValue)} />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField fullWidth label='Usuário' autoComplete='usuario' {...applyProps('usuario')} />
          </Grid>
          <Grid item xs={12}>
            <Password fullWidth label='Senha' autoComplete='senha-atual' {...applyProps('senha')} />
          </Grid>
          <Grid item xs={12}>
            <ProgressButton type='submit' label='Entrar' fullWidth />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default compose(
  withSnackbar,
  withApollo,
  graphql(LISTAR_CLIENTE, {
    name: 'cliente',
    skip: ({ match }) => !match.params.colegio,
    options: ({ match }) => ({
      variables: {
        alias: match.params.colegio
      }
    })
  }),
  graphql(LOGIN_CLIENTE, { name: 'login' }),
  withFormik({
    enableReinitialize: true,
    validateOnChange: false,
    mapPropsToValues: ({ cliente = {} }) => ({
      clienteId: cliente.listarCliente ? parseInt(cliente.listarCliente.id) : null,
      clienteNome: '',
      usuario: '',
      senha: ''
    }),
    validationSchema: Yup.object().shape({
      clienteId: Yup.number().nullable().required('Selecione o cliente'),
      usuario: Yup.string().required('Informe o usuário.'),
      senha: Yup.string().required('Informe a senha.')
    }),
    handleSubmit: async ({ usuario, senha, clienteId }, { props: { enqueueSnackbar, login, history }, setSubmitting }) => {
      try {
        const response = await login({ variables: { usuario, senha, clienteId } })
        if (!response.data.loginCliente) {
          throw new Error('Erro ao fazer o login.')
        }

        localStorage.setItem('AUTH_TOKEN', response.data.loginCliente)
        history.push('/')
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' })
      } finally {
        setSubmitting(false)
      }
    }
  })
)(Login)
