import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core'

const ProgressButton = ({ loading, label, circularProps, disabled, color, icon: Icon, ...props }) => {
  if (Icon) {
    return (
      <Tooltip title={label} color={loading ? 'inherit' : (color || 'primary')} disabled={disabled || loading} {...props}>
        <IconButton>
          {!loading && <Icon />}
          {loading && <CircularProgress size={24} color='secondary' disabled={loading || disabled} {...circularProps} />}
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Button color={loading ? 'inherit' : (color || 'primary')} {...props} disabled={disabled || loading}>
      {label}
      {loading && <CircularProgress size={24} style={{ marginLeft: 15 }} color='secondary' disabled={loading || disabled} {...circularProps} />}
    </Button>
  )
}

ProgressButton.defaultProps = {
  label: 'Salvar',
  loading: false,
  circularProps: {}
}

ProgressButton.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  circularProps: PropTypes.object
}

export default ProgressButton
