import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { withApollo } from 'react-apollo'

import { Container, Grid, Typography, CircularProgress, Button } from '@material-ui/core'

import logo from 'Assets/images/logo.png'
import { authToken as useStyles } from './styles'
import { VERIFICA_TOKEN } from 'Graphql/cliente'

const Loading = () => (
  <>
    <Grid item xs={12}>
      <Typography align='center'>
        Autenticação via <b>token</b>, aguarde um momento...
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <CircularProgress />
    </Grid>
  </>
)

const Error = ({ error }) => (
  <>
    <Grid item xs={12} component={Typography} align='center'>
      {error}
    </Grid>
    <Grid item xs={12}>
      <Button variant='outlined' fullWidth component={Link} to='/' >Voltar ao Login</Button>
    </Grid>
  </>
)

Error.defaultProps = {
  error: 'Não foi possível autenticar com o token informado.'
}

const AuthToken = ({ client, match: { params: { token } }, history }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const verificaToken = async () => {
      try {
        if (!token) throw new Error('Token não informado.')

        localStorage.setItem('AUTH_TOKEN', token)

        const response = await client.query({ query: VERIFICA_TOKEN, variables: { token } })
        setLoading(false)

        if (!response.data.clienteVerificaToken) {
          setError('Token inválido.')
        }

        history.push('/')
      } catch (e) {
        setLoading(false)
        setError('Token Inválido.')
        localStorage.removeItem('AUTH_TOKEN')
      }
    }
    verificaToken()
  }, [token, history, client])

  return (
    <Container component='main' maxWidth='xs'>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} className={classes.logoDiv}>
          <img src={logo} alt='Logo delta' className={classes.logo} />
        </Grid>
        {(loading && !error) && <Loading />}
        {(!loading && error) && <Error error={error} />}
      </Grid>
    </Container>
  )
}

export default withApollo(AuthToken)
