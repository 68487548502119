import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import MainContainer from './Components/MainContainer'
import { Login, LoginInterno, AuthToken } from './Containers/Login'
import Logout from './Containers/Logout'
import { VerificaLogin, VerificaLoginInterno } from './Components/VerificaLogin'

import client from './client'
import theme from './Assets/theme/default'
import { routes, routesInternal } from './Routes'

import 'moment/locale/pt-br'

function App () {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
          <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000}>
            <BrowserRouter>
              <Switch>
                <Route path='/login/token/:token' exact component={AuthToken} />
                <Route path='/interno/login' exact component={LoginInterno} />
                <Route path='/login' exact component={Login} />
                <Route path='/:colegio/login' exact component={Login} />
                <Route path='/logout' exact component={Logout} />
                <Route path='/interno'>
                  <VerificaLoginInterno>
                    <MainContainer title='NF Interno' routes={routesInternal} />
                  </VerificaLoginInterno>
                </Route>
                <Route path='/'>
                  <VerificaLogin>
                    <MainContainer title='Delta NF' routes={routes} />
                  </VerificaLogin>
                </Route>
              </Switch>
            </BrowserRouter>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
