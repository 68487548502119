import React from 'react'

import { Grid, TextField } from '@material-ui/core'

export default ({ inputProps }) => (
  <>
    <Grid item xs={12} md={2}>
      <TextField label='IR' placeholder='0.0000' {...inputProps('aliquotas.ir')} />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='CSLL' placeholder='0.0000' {...inputProps('aliquotas.csll')} />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='ISS' placeholder='0.0000' {...inputProps('aliquotas.iss')} />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='PIS' placeholder='0.0000' {...inputProps('aliquotas.pis')} />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='COFINS' placeholder='0.0000' {...inputProps('aliquotas.cofins')} />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='INSS' placeholder='0.0000' {...inputProps('aliquotas.inss')} />
    </Grid>
  </>
)
