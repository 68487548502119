import React from 'react'
import PropTypes from 'prop-types'

import { Avatar } from '@material-ui/core'

import { getInitials, stringToColor } from 'Utils'

const AvatarComponent = ({ label, color, src, alt, ...props }) => {
  const initials = getInitials(label)
  const bgColor = color || stringToColor(initials)

  if (src) {
    return <Avatar src={src} alt={alt} {...props} />
  }

  return (
    <Avatar alt={alt || label} style={{ backgroundColor: bgColor }} {...props}>
      {initials}
    </Avatar>
  )
}

const errorMessage = 'Do you need pass attribute "src" or "initials".'

AvatarComponent.propTypes = {
  color: PropTypes.string,
  src: (props, propName, componentName) => {
    if (!props['label'] && !props[propName]) {
      return new Error(errorMessage)
    }
  },
  label: (props, propName, componentName) => {
    if (!props['src'] && !props[propName]) {
      return new Error(errorMessage)
    }
  }
}

export default AvatarComponent
