import React, { useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose, graphql } from 'react-apollo'
import { Form, withFormik } from 'formik'
import { withSnackbar } from 'notistack'
import * as Yup from 'yup'

import { Grid, TextField, AppBar, Typography, Button } from '@material-ui/core'

import ProgressButton from 'Components/Buttons/ProgressButton'
import Password from 'Components/Inputs/Password'
import { LOGIN_INTERNO } from 'Graphql/usuario'
import { loginInterno as useStyles } from './styles'
import logo from 'Assets/images/logo.png'

const LoginInterno = ({ values, errors, handleChange, isSubmitting, dirty, ...props }) => {
  const classes = useStyles()
  const applyProps = (id) => ({
    id: id,
    name: id,
    value: values[id],
    onChange: handleChange,
    error: !!errors[id],
    helperText: errors[id]
  })

  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <>
      <AppBar>
        <Typography className={classes.title} variant='h5'>Delta NF - Interno</Typography>
      </AppBar>
      <div className={classes.container}>
        <Grid component={Form} container spacing={2} justify='center' alignContent='center' alignItems='center'>
          <Grid item xs={12} sm={8} md={6} lg={4} container spacing={2}>
            <Grid item xs={12} className={classes.imgDiv}>
              <img src={logo} alt='Logo Delta' />
            </Grid>
            <Grid item xs={12}>
              <TextField label='Usuário' {...applyProps('usuario')} />
            </Grid>
            <Grid item xs={12}>
              <Password label='Senha' {...applyProps('senha')} />
            </Grid>
            <Grid item xs={12} container justify='space-between'>
              <Button variant='outlined' component={Link} to='/'>Início</Button>
              <ProgressButton type='submit' label='Entrar' loading={isSubmitting} disabled={!dirty} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default compose(
  withRouter,
  withSnackbar,
  graphql(LOGIN_INTERNO, { name: 'loginInterno' }),
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({ usuario: '', senha: '' }),
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      usuario: Yup.string().required('Informe o usuário.'),
      senha: Yup.string().required('Informe a senha.')
    }),
    handleSubmit: async ({ usuario, senha }, { props: { loginInterno, enqueueSnackbar, history }, setSubmitting }) => {
      try {
        const response = await loginInterno({ variables: { usuario, senha } })
        if (response.data && response.data.loginInterno) {
          localStorage.setItem('INTERNO', true)
          localStorage.setItem('AUTH_TOKEN', response.data.loginInterno)

          history.push('/interno')
        }
      } catch (error) {
        localStorage.clear()
        enqueueSnackbar(error.message, { variant: 'error' })
      } finally {
        setSubmitting(false)
      }
    }
  })
)(LoginInterno)
