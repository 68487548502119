import React from 'react'

import { Redirect } from 'react-router-dom'

const Logout = () => {
  window.localStorage.removeItem('AUTH_TOKEN')
  return <Redirect to='/login' />
}

export default Logout
