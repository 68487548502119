import { createMuiTheme } from '@material-ui/core/styles'
import Green from '@material-ui/core/colors/green'

export default createMuiTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary'
    }
  },
  pallete: {
    green: Green
  }
})
