import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import Header from "./Header";
import Sidebar, { SidebarContext } from "./Sidebar";
import { useStylesMainContainer as useStyles } from "./styles";

const AppHeaderAndSiderbar = ({ routes, title }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <SidebarContext.Provider value={{ isOpen, setOpen }}>
      <Header title={title} />
      <Sidebar routes={routes} />
    </SidebarContext.Provider>
  );
};

const MainContainer = ({ routes, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeaderAndSiderbar routes={routes} title={title} />
      <Container maxWidth={false}>
        <div className={classes.toolbar} />
        <div className={classes.mainContainer}>
          <Switch>
            {routes.map((r) => (
              <Route key={r.path} path={r.path} component={r.component} exact />
            ))}
          </Switch>
        </div>
      </Container>
    </div>
  );
};

export default MainContainer;
