import React, { useEffect } from 'react'
import { compose, graphql } from 'react-apollo'
import _cloneDeep from 'lodash/cloneDeep'
import _sortBy from 'lodash/sortBy'
import { useSnackbar } from 'notistack'

import { Grid, Paper, Typography, List, LinearProgress } from '@material-ui/core'

import { LISTAR_CLIENTES, REMOVER_CLIENTE, SUBSCRIBE_CLIENTES } from 'Graphql/cliente'

import useStyles from './styles'
import Item from './components/Item'
import NenhumItem from './components/NenhumItem'

const updateQuery = (previous, { subscriptionData: { data: { clienteSubscription } } }) => {
  const tmp = _cloneDeep(previous)
  const { action, cliente } = clienteSubscription

  switch (action) {
    case 'CLIENTE_ADDED':
      tmp.listarClientes.push(cliente)
      break
    case 'CLIENTE_DELETED':
      tmp.listarClientes = tmp.listarClientes.filter(c => c.codigo !== cliente.codigo)
      break
    default:
      break
  }

  tmp.listarClientes = _sortBy(tmp.listarClientes, c => c.nome)
  return tmp
}

const ListarClientes = ({ clientes: { loading, listarClientes: clientes, subscribeToMore }, removerCliente, history }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const unsubscribe = subscribeToMore({ document: SUBSCRIBE_CLIENTES, updateQuery })
    return () => unsubscribe()
  }, [subscribeToMore])

  if (loading) {
    return (<LinearProgress />)
  }

  const deletar = async (codigo) => {
    const response = await removerCliente({ variables: { codigo: parseInt(codigo) } })
    if (response.data.removerCliente) {
      enqueueSnackbar('Cliente excluído com sucesso!', { variant: 'success' })
    } else {
      enqueueSnackbar('Este cliente já foi exlcuído!', { variant: 'warning' })
    }
  }

  let lista = null
  if (!clientes || clientes.length === 0) {
    lista = <NenhumItem />
  } else {
    lista = clientes.map((cliente, i) => <Item key={cliente.codigo} cliente={cliente} removerCliente={deletar} divider={i !== (clientes.length - 1)} history={history} />)
  }

  return (
    <Grid container component={Paper} className={classes.root}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6'>Clientes</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            {lista}
          </List>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default compose(
  graphql(REMOVER_CLIENTE, { name: 'removerCliente' }),
  graphql(LISTAR_CLIENTES, { name: 'clientes' })
)(ListarClientes)
