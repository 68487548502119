import React from 'react'

import { Grid, TextField, MenuItem } from '@material-ui/core'

import RefreshButton from 'Components/Buttons/RefreshButton'
import TelefoneMask from 'Components/Inputs/Telefone'
import CNPJMask from 'Components/Inputs/CNPJ'

export default ({ inputProps, status, buscarCNPJ, prefeitura }) => (
  <>
    <Grid item xs={12} md={6}>
      <TextField label='Plataforma de Integração' select {...inputProps('plataforma')}>
        <MenuItem value='DESATIVADO'>
          <em>Desativado</em>
        </MenuItem>
        <MenuItem value='ENOTAS'>eNotas</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='CNPJ' placeholder='00.000.000/0000-00' {...inputProps('cnpj')}
        InputProps={{
          inputComponent: CNPJMask,
          endAdornment: (<RefreshButton loading={status.consultarCNPJ} onClick={() => buscarCNPJ()} />)
        }} />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Razão Social' {...inputProps('razaoSocial')} />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Nome Fantasia' {...inputProps('nomeFantasia')} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField label='Telefone Comercial' placeholder='(00) 0000-0000' InputProps={{ inputComponent: TelefoneMask }} {...inputProps('telefone')} />
    </Grid>
    <Grid item xs={12} md={8}>
      <TextField label='Email' placeholder='email@email.com' {...inputProps('email')} />
    </Grid>
    <Grid item xs={12}>
      <TextField label='Descrição do Serviço Prestado' {...inputProps('descricaoServico')} disabled={!prefeitura.consultaCaracteristicasPrefeitura.usaDescricaoServico} />
    </Grid>
  </>
)
