import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import {
  useTheme,
  useMediaQuery,
  AppBar,
  Tooltip,
  Toolbar,
  Typography,
  IconButton
} from '@material-ui/core'

import LogoutIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'

import { SidebarContext } from './Sidebar'
import { useStylesHeader as useStyles } from './styles'

const Header = ({ title }) => {
  const classes = useStyles()
  const context = useContext(SidebarContext)
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar>
        {mobile
          ? <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='Menu' onClick={() => context.setOpen(true)}>
            <MenuIcon />
          </IconButton>
          : null}
        <Typography variant='h6' className={classes.title}>
          {title}
        </Typography>
        <Tooltip title='Sair'>
          <IconButton edge='end' color='inherit' aria-label='Sair' component={Link} to='/logout'>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

Header.defaultProps = {
  title: 'Delta NF'
}

Header.propTypes = {
  title: PropTypes.string.isRequired
}

export default Header
