import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 300

export const useStylesSidebar = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar
}))

export const useStylesMainContainer = makeStyles(theme => ({
  '@global': {
    body: {
      margin: 0
    }
  },
  root: {
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar,
  mainContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

export const useStylesHeader = makeStyles(theme => ({
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}))

export const useStylesProfile = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
  userName: {
    fontWeight: 500,
    color: '#fff',
    height: 25
  },
  wihteText: {
    color: '#fff'
  },
  dividerGrid: {
    padding: 0
  },
  divider: {
    color: '#fff'
  }
}))
