import React from 'react'

import { Grid, TextField, CircularProgress } from '@material-ui/core'
import CEPMask from 'Components/Inputs/CEP'

export default ({ status, onChangeCEP, inputProps }) => (
  <>
    <Grid item xs={12} md={3}>
      <TextField label='CEP' placeholder='00000-000'
        InputProps={{
          inputComponent: CEPMask,
          endAdornment: status.consultarCEP ? (<CircularProgress size={24} />) : null
        }}
        {...inputProps('endereco.cep')}
        onChange={onChangeCEP}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <TextField label='Estado' {...inputProps('endereco.estado')} disabled={status.consultarCEP} />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Cidade' {...inputProps('endereco.cidade')} disabled={status.consultarCEP} />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Bairro' {...inputProps('endereco.bairro')} disabled={status.consultarCEP} />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Endereço' {...inputProps('endereco.logradouro')} disabled={status.consultarCEP} />
    </Grid>
    <Grid item xs={12} md={2}>
      <TextField label='Número' {...inputProps('endereco.numero')} />
    </Grid>
    <Grid item xs={12} md={10}>
      <TextField label='Complemento' {...inputProps('endereco.complemento')} />
    </Grid>
  </>
)
