import React, { useState } from 'react'

import { TextField, InputAdornment, IconButton } from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const Password = (props) => {
  const [show, setShow] = useState(false)

  return (
    <TextField
      type={show ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton aria-label='Ver senha' onClick={() => setShow(!show)}>
              {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  )
}

export default Password
