import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  makeStyles,
  TableCell,
  TableRow as TableRowMUi,
  Grid,
  Tooltip,
  Typography,
  useTheme,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  Checkbox
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import DoneIcon from '@material-ui/icons/Done'
import SendIcon from '@material-ui/icons/Send'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ClearIcon from '@material-ui/icons/Clear'
import { compose, graphql } from 'react-apollo'
import { CANCELAR_NOTA } from 'Graphql/notas'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    maxWidth: 400
  },
  icon: {
    minWidth: 40
  },
  tooltip: {
    display: 'flex'
  }
}))

function Status({ nota }) {
  const classes = useStyles()
  const theme = useTheme()

  if (nota.status === 'NEGADA') {
    return (
      <Tooltip
        title={nota.motivoStatus || 'Nota Negada'}
        className={classes.tooltip}
      >
        <CancelIcon color='error' />
      </Tooltip>
    )
  }

  if (nota.status === 'ENVIANDO') {
    return (
      <Tooltip title='Enviando' className={classes.tooltip}>
        <SendIcon color='secondary' />
      </Tooltip>
    )
  }

  if (nota.status === 'CANCELADA') {
    return (
      <Tooltip title='Cancelada' className={classes.tooltip}>
        <ClearIcon color='error' />
      </Tooltip>
    )
  }

  return (
    <Tooltip title='Autorizada' className={classes.tooltip}>
      <a href={nota.linkPDF}>
        <DoneIcon style={{ color: theme.pallete.green[500] }} />
      </a>
    </Tooltip>
  )
}

function TableRow({
  nota,
  selecionarNota,
  notaSelecionada,
  loading,
  ...props
}) {
  const classes = useStyles()
  const ITEM_HEIGHT = 48
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (event) => {
    selecionarNota()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickMenu = (link) => {
    handleClose()
    window.open(link)
  }

  const handleClickCancelar = async () => {
    handleClose()
    const ret = await props.cancelar({
      variables: {
        id: nota.id
      }
    })

    if (ret.data.cancelarNota) {
      enqueueSnackbar('Nota cancelada com sucesso!', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar('Não foi possí­vel cancelar a nota selecionada', {
        variant: 'error'
      })
    }
  }

  return (
    <TableRowMUi>
      <TableCell>
        <Checkbox
          color='primary'
          type='checkbox'
          name={'nota-' + nota.id}
          id={'nota-' + nota.id}
          value={nota.id}
          checked={notaSelecionada}
          onChange={handleSelect}
          disabled={nota.status === 'CANCELADA' || loading === true}
        />
      </TableCell>
      <TableCell>
        <Grid container justify='flex-start' alignItems='center' wrap='nowrap'>
          <Grid item container xs={1} className={classes.icon}>
            <Status nota={nota} />
          </Grid>
          <Grid item>
            <Typography variant='body2' noWrap className={classes.title}>
              {nota.aluno.nome || nota.tomador.nome}
            </Typography>
            {nota.aluno.nome && (
              <Typography
                variant='caption'
                color='inherit'
                noWrap
                className={classes.title}
              >
                {nota.tomador.nome}{' '}
              </Typography>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2' className={classes.title}>
          {moment(nota.dataCompetencia).add(1, 'days').format('DD/MM/YYYY')}
        </Typography>
        {nota.emitidoEm && (
          <Typography
            variant='caption'
            color='inherit'
            noWrap
            className={classes.title}
          >
            Emitida em: {moment(nota.emitidoEm).format('DD/MM/YYYY')}
          </Typography>
        )}
      </TableCell>
      <TableCell align='right'>
        <Grid container direction='column'>
          <Typography variant='body2' className={classes.title}>
            R$ {nota.valorReferencia.toFixed(2)}
          </Typography>
          <Typography
            variant='caption'
            color='inherit'
            noWrap
            className={classes.title}
          >
            Ref: {nota.idExterno}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2' noWrap>
          R$ {nota.valor.toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell align='right'>
        <Typography variant='body2' noWrap>
          R$ {nota.aliquotas.valorTotal.toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell align='right'>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5
            }
          }}
        >
          <MenuItem
            disabled={!nota.linkPDF || loading === true}
            onClick={() => handleClickMenu(nota.linkPDF)}
          >
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
            Baixar PDF
          </MenuItem>
          <MenuItem
            disabled={!nota.linkXML || loading === true}
            onClick={() => handleClickMenu(nota.linkXML)}
          >
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
            Baixar XML
          </MenuItem>
          <MenuItem
            disabled={
              !nota.status === 'CANCELADA' || loading === true || notaSelecionada === true
            }
            onClick={() => handleClickCancelar()}
          >
            <ListItemIcon>
              <DeleteForeverIcon />
            </ListItemIcon>
            Cancelar
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRowMUi>
  )
}
TableRow.propTypes = {
  nota: PropTypes.shape({
    status: PropTypes.string.isRequired,
    linkPDF: PropTypes.string,
    aluno: PropTypes.shape({
      nome: PropTypes.string
    }).isRequired,
    tomador: PropTypes.shape({
      nome: PropTypes.string
    }).isRequired,
    valorReferencia: PropTypes.number.isRequired,
    idExterno: PropTypes.string.isRequired,
    valor: PropTypes.number.isRequired,
    aliquotas: PropTypes.shape({
      valorTotal: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  selecionarNota: PropTypes.func.isRequired,
  notaSelecionada: PropTypes.bool.isRequired
}

export default compose(graphql(CANCELAR_NOTA, { name: 'cancelar' }))(TableRow)
