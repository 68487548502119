import React from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableRow, Typography } from '@material-ui/core'
function TableFooter ({ notas, ...props }) {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Typography variant='h6' noWrap>Total</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' noWrap align='right'>
          R$ {notas.reduce((last, current) => last + current.valorReferencia, 0).toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' noWrap align='right'>
          R$ {notas.reduce((last, current) => last + current.valor, 0).toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' noWrap align='right'>
          R$ {notas.reduce((last, current) => last + current.aliquotas.valorTotal, 0).toFixed(2)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

TableFooter.propTypes = {
  notas: PropTypes.arrayOf(
    PropTypes.shape({
      aliquotas: PropTypes.shape({
        valorTotal: PropTypes.number.isRequired
      }).isRequired,
      valor: PropTypes.number.isRequired,
      valorReferencia: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
}

export default TableFooter
