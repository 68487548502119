import React from 'react'
import MaskedInput from 'react-text-mask'

const CEPMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      keepCharPositions={false}
      guide={false}
      showMask
    />
  )
}

export default CEPMask
