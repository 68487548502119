import React, { useState } from 'react'
import get from 'lodash/get'
import omit from 'omit-deep-lodash'
import { withFormik, Form } from 'formik'
import { compose, graphql, withApollo } from 'react-apollo'
import { withSnackbar } from 'notistack'
import * as Yup from 'yup'

import { TextField, MenuItem, Grid, Paper, LinearProgress } from '@material-ui/core'

import { LISTAR_EMPRESAS, UPDATE_EMPRESA, CONSULTAR_PREFEITURA } from 'Graphql/empresa'
import { CONSULTAR_CEP, CONSULTAR_RECEITAWS } from 'Graphql/utils'

import useStyles from './styles'
import Endereco from './components/Endereco'
import Empresa from './components/Empresa'
import Nota from './components/Nota'
import ProgressButton from 'Components/Buttons/ProgressButton'
import Impostos from './components/Impostos'
import Autenticacao from './components/Autenticacao'
import ConfirmaHomologacao from './components/ConfirmaHomologacao'

const Config = ({
  handleChange,
  setFieldValue,
  isSubmitting,
  setValues,
  setStatus,
  values,
  status,
  errors,
  empresas,
  prefeitura = { loading: true },
  client,
  enqueueSnackbar
}) => {
  const classes = useStyles()
  const [confirmaHomologacao, setConfirmaHomologacao] = useState(false)

  React.useEffect(() => {
    if (!values.tipoAutenticacao && prefeitura.consultaCaracteristicasPrefeitura && prefeitura.consultaCaracteristicasPrefeitura.tipoAutenticacao) {
      setFieldValue('tipoAutenticacao', prefeitura.consultaCaracteristicasPrefeitura.tipoAutenticacao)
    }

    if (prefeitura.error) {
      enqueueSnackbar(prefeitura.error.message, { variant: 'error' })
    }
  }, [enqueueSnackbar, prefeitura.error, prefeitura.consultaCaracteristicasPrefeitura, setFieldValue, values.tipoAutenticacao])

  if (empresas.loading) {
    return (<LinearProgress />)
  }

  if (!empresas.listarEmpresas) {
    empresas.listarEmpresas = []
  }

  if (!prefeitura.consultaCaracteristicasPrefeitura) {
    prefeitura.consultaCaracteristicasPrefeitura = {}
  }

  const onChangeEmpresa = async (e) => {
    handleChange(e)

    const empresa = empresas.listarEmpresas.find(empresa => empresa.idExterno === e.target.value)

    if (empresa) {
      setStatus({ ...status, id: parseInt(empresa.id) })
      delete empresa.id
      setValues(omit(empresa, '__typename'))
    }
  }

  const onChangeCEP = async (e) => {
    handleChange(e)

    if (e.target.value.length !== 9) {
      return
    }

    setStatus({ consultarCEP: true })

    try {
      const { data: { consultaCEP: { __typename, ...dadosCEP } }} = await client.query({
        query: CONSULTAR_CEP,
        variables: { cep: e.target.value.replace('-', '') }
      })

      // É possível utilizar o setValues, porém se alguem digitar algo no formulário no intervalo de tempo que a query ConsultaCEP demorar para responder, será perdido.
      setValues({ ...values, endereco: { ...values.endereco, ...dadosCEP, cep: e.target.value } })
    } catch (e) {
      enqueueSnackbar(e.message,{
        variant: 'error'
      })      
    }

    setStatus({ consultarCEP: false })
  }

  const inputProps = (id) => {
    return {
      id: id,
      name: id,
      onChange: handleChange,
      fullWidth: true,
      variant: 'outlined',
      value: get(values, id) || '',
      autoComplete: '0',
      disabled: status.consultarCEP,
      error: !!get(errors, id),
      helperText: get(errors, id) || ''
    }
  }

  const checkboxProps = (id) => {
    return {
      id: id,
      name: id,
      checked: !!values[id],
      onChange: handleChange
    }
  }

  const buscarCNPJ = async () => {
    try {
      setStatus({ ...status, consultarCNPJ: true })
      const { data: { buscarEmpresaReceitaFederal: dados } = {} } = await client.query({ query: CONSULTAR_RECEITAWS, variables: { cnpj: values.cnpj } })
      if (dados) {
        setValues({
          ...values,
          razaoSocial: dados.nome || values.razaoSocial,
          nomeFantasia: dados.fantasia || values.nomeFantasia,
          telefone: dados.telefone ? dados.telefone.split('/')[0] : values.telefone,
          email: dados.email || values.email
        })
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } finally {
      setStatus({ ...status, consultarCNPJ: false })
    }
  }

  return (
    <Form>
      <ConfirmaHomologacao open={confirmaHomologacao} handleClose={() => setConfirmaHomologacao(!confirmaHomologacao)} email={values.email} empresaId={status.id} />
      <Grid container component={Paper} className={classes.root}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <TextField label='Empresa' select {...inputProps('idExterno')} onChange={onChangeEmpresa}>
              {empresas.listarEmpresas.map(empresa => <MenuItem key={empresa.idExterno} value={empresa.idExterno}>{`${empresa.cnpj} - ${empresa.razaoSocial}`}</MenuItem>)}
              {empresas.listarEmpresas.length === 0 && <MenuItem><em>Nenhuma</em></MenuItem>}
            </TextField>
          </Grid>
          {values.idExterno && (
            <>
              <Empresa inputProps={inputProps} status={status} buscarCNPJ={buscarCNPJ} prefeitura={prefeitura} />
              <Endereco status={status} onChangeCEP={onChangeCEP} inputProps={inputProps} />
              <Nota inputProps={inputProps} checkboxProps={checkboxProps} prefeitura={prefeitura} />
              <Impostos inputProps={inputProps} />
              <Autenticacao tipoAutenticacao={values.tipoAutenticacao} certificado={values.certificado} error={errors.certificado} inputProps={inputProps} setFieldValue={setFieldValue} />
              <Grid item container justify='flex-end' spacing={2}>
                {!values.homologado && (
                  <Grid item>
                    <ProgressButton disabled={!values.plataformaId} onClick={() => setConfirmaHomologacao(true)} loading={isSubmitting} label='Homologar' />
                  </Grid>
                )}
                <Grid item><ProgressButton type='submit' loading={isSubmitting} /></Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  )
}

export default compose(
  withSnackbar,
  graphql(LISTAR_EMPRESAS, { name: 'empresas', options: { fetchPolicy: 'network-only' } }),
  graphql(UPDATE_EMPRESA, { name: 'adicionarEmpresaMutation' }),
  withFormik({
    validateOnChange: false,
    mapPropsToValues: () => ({
      idExterno: '',
      plataforma: 'DESATIVADO',
      tipoAutenticacao: null
    }),
    mapPropsToStatus: () => ({
      consultarCEP: false,
      consultarCNPJ: false
    }),
    validationSchema: Yup.object().shape({
      cnpj: Yup.string().nullable().matches(/^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}$/, 'CNPJ inválido').required('Campo obrigatório'),
      razaoSocial: Yup.string().required('Campo obrigatório'),
      nomeFantasia: Yup.string().required('Campo obrigatório'),
      email: Yup.string().email('E-mail inválido'),
      telefone: Yup.string().nullable().matches(/^\([0-9]{2}\) [0-9]{4}-[0-9]{4}$/, 'Telefone inválido').required('Campo obrigatório'),
      inscricaoMunicipal: Yup.string().nullable().required('Campo obrigatório'),
      tipoEnvio: Yup.mixed().oneOf(['RECEBIMENTO', 'VENCIMENTO'], 'O tipo de envio deve ser entre: Recebimento ou Vencimento').required('Campo Obrigatório'),
      endereco: Yup.object().shape({
        cep: Yup.string().matches(/^[0-9]{5}-[0-9]{3}$/, 'CEP inválido').required('Campo obrigatório'),
        estado: Yup.string().length(2, 'Estado inválido').required('Campo obrigatório'),
        cidade: Yup.string().required('Campo obrigatório'),
        bairro: Yup.string().required('Campo obrigatório'),
        logradouro: Yup.string().required('Campo obrigatório'),
        numero: Yup.string().required('Campo obrigatório')
      }),
      usuario: Yup.string().when('tipoAutenticacao', {
        is: 'USUARIO_E_SENHA',
        then: Yup.string().nullable().required('Campo obrigatório'),
        otherwise: Yup.string().nullable()
      }),
      senha: Yup.string().when('tipoAutenticacao', {
        is: (value) => ['USUARIO_E_SENHA', 'CERTIFICADO'].includes(value),
        then: Yup.string().nullable().required('Campo obrigatório'),
        otherwise: Yup.string().nullable()
      }),
      token: Yup.string().when('tipoAutenticacao', {
        is: 'TOKEN',
        then: Yup.string().nullable().required('Campo obrigatório'),
        otherwise: Yup.string().nullable()
      })
    }),
    handleSubmit: async ({ aliquotas, tipoAutenticacao, ...values }, { setSubmitting, props: { adicionarEmpresaMutation, enqueueSnackbar } }) => {
      try {
        delete values.plataformaId
        await adicionarEmpresaMutation({
          variables: {
            empresa: {
              ...values,
              aliquotas: {
                ir: parseFloat(aliquotas.ir),
                csll: parseFloat(aliquotas.csll),
                iss: parseFloat(aliquotas.iss),
                pis: parseFloat(aliquotas.pis),
                cofins: parseFloat(aliquotas.cofins),
                inss: parseFloat(aliquotas.inss)
              }
            }
          }
        })
        enqueueSnackbar('Empresa atualizada.', { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' })
      } finally {
        setSubmitting(false)
      }
    }
  }),
  withApollo,
  graphql(CONSULTAR_PREFEITURA, {
    name: 'prefeitura',
    skip: ({ values }) => !values.endereco || !values.endereco.codigoIbgeCidade || values.endereco.codigoIbgeCidade === '',
    options: ({ values }) => ({
      variables: { codigoIbge: parseInt(values.endereco.codigoIbgeCidade) }
    })
  })
)(Config)
