import { makeStyles } from '@material-ui/core'

export const login = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

export const loginInterno = makeStyles(theme => ({
  container: {
    minHeight: '80vh',
    display: 'flex'
  },
  title: {
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10
  },
  imgDiv: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export const authToken = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: '80%'
  }
}))
