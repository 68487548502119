import React from "react";
import {
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    width: "100%",
    overflowX: "auto",
    maxHeight: 500,
  },
  cellPadding: {
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const TabelaPrincipal = ({ notas, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.tableWrapper}>
      <Table stickyHeader size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cellPadding}>Cod. Cliente</TableCell>
            <TableCell className={classes.cellPadding}>Nome</TableCell>
            <TableCell className={classes.cellPadding}>Plataforma</TableCell>
            <TableCell className={classes.cellPadding}>Tipo de Envio</TableCell>
            <TableCell className={classes.cellPadding}>Ult. Aut.</TableCell>
            <TableCell className={classes.cellPadding}>Ult. RPS</TableCell>
            <TableCell className={classes.cellPadding}>Aut./Mês</TableCell>
            <TableCell className={classes.cellPadding}>Env./Mês</TableCell>
            <TableCell className={classes.cellPadding}>Neg./Mês</TableCell>
            <TableCell className={classes.cellPadding}>Reportar</TableCell>
            <TableCell className={classes.cellPadding}>Simples</TableCell>
            <TableCell className={classes.cellPadding}>Aliq.</TableCell>
            <TableCell className={classes.cellPadding}>ISS</TableCell>
            <TableCell className={classes.cellPadding}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notas.length === 0 && (
            <TableRow>
              <TableCell>
                <Typography variant="body2">Nenhuma nota</Typography>
              </TableCell>
            </TableRow>
          )}
          {notas.length > 0 &&
            notas.map((nota) => {
              return (
                <TableRow key={nota.idEmpresa}>
                  <TableCell className={classes.cellPadding}>
                    {nota.codigoCliente}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.nomeCliente}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.plataformaEmpresa}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.tipoEnvioEmpresa}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.ultimaAutorizacao}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.ultimoRps}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.qtdNotasStatusAutorizadaNoMesDeCompetencia}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.qtdNotasStatusEnviandoNoMesDeCompetencia}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.qtdNotasStatusNegadasNoMesDeCompetencia}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.Reportar}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.Simples}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {nota.aliquota}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {(Number(nota.issEmpresa) * 0.01).toLocaleString("pt-BR", {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell className={classes.cellPadding}>
                    {Number(
                      nota.valorTotalNotasAutorizadasNoMesDeCompetencia
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default TabelaPrincipal;
