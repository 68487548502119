import React from 'react'

import { Redirect } from 'react-router-dom'

const VerificaLoginInterno = ({ children }) => {
  if (localStorage.getItem('AUTH_TOKEN') === null || !localStorage.getItem('INTERNO')) {
    return <Redirect to='/interno/login' />
  }

  return children
}

export default VerificaLoginInterno
