import React from 'react'

import { Redirect } from 'react-router-dom'
import { GET_USUARIO_LOGADO } from 'Graphql/usuario'
import { graphql } from 'react-apollo'

const VerificaLogin = ({ usuario, children }) => {
  if (usuario.loading) {
    return null
  }

  if (usuario.error) {
    localStorage.removeItem('AUTH_TOKEN')
    return <Redirect to='/login' />
  }

  if (localStorage.getItem('AUTH_TOKEN') === null || localStorage.getItem('INTERNO')) {
    return <Redirect to='/login' />
  }

  return children
}

export default graphql(GET_USUARIO_LOGADO, { name: 'usuario' })(VerificaLogin)
