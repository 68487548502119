import React from 'react'
import PropTypes from 'prop-types'

import { Dialog, DialogActions, DialogContent, Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  actions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`
  }
}))

function Modal ({ open, handleClose, children, ...props }) {
  const classes = useStyles()
  return (
    <Dialog
      open={open} onClose={handleClose}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color='primary' variant='outlined' autoFocus>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}
Modal.defaultProps = {
  open: false
}
Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Modal
