import React from 'react'
import MaskedInput from 'react-text-mask'

const CNPJMask = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      keepCharPositions={false}
      guide={false}
      showMask
    />
  )
}

export default CNPJMask
