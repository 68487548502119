import React from "react";
import { TableHead, TableRow, TableCell, Button } from "@material-ui/core";
import PropTypes from "prop-types";

function TableHeader({
  limparNotasSelecionadas,
  temNotasSelecionadas = false,
  ...props
}) {
  const handleLimparSelecaoClick = (event) => {
    event.preventDefault();
    limparNotasSelecionadas();
  };

  return (
    <TableHead>
      <TableRow>
        {temNotasSelecionadas === true ? (
          <TableCell onClick={handleLimparSelecaoClick}>
            <Button onClick={handleLimparSelecaoClick}>Limpar Seleção</Button>
          </TableCell>
        ) : (
          <TableCell>Selecionar</TableCell>
        )}
        <TableCell>Aluno / Responsável</TableCell>
        <TableCell align="right">Data</TableCell>
        <TableCell align="right">Mensalidade</TableCell>
        <TableCell align="right">Valor Nota</TableCell>
        <TableCell align="right">Impostos</TableCell>
        <TableCell align="center">Ações</TableCell>
      </TableRow>
    </TableHead>
  );
}
TableHeader.propTypes = {
  temNotasSelecionadas: PropTypes.bool.isRequired,
  limparNotasSelecionadas: PropTypes.func.isRequired,
};

export default TableHeader;
