import React from 'react'
import { graphql } from 'react-apollo'

import { Grid, CircularProgress, Typography } from '@material-ui/core'

import Avatar from 'Components/Avatar'
import { GET_USUARIO_LOGADO } from 'Graphql/usuario'
import { useStylesProfile as useStyles } from './styles'

const Profile = ({ usuario: { loading, usuarioLogado: usuario } }) => {
  const classes = useStyles()

  if (loading) {
    return (
      <Grid container spacing={2} justify='center'>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.container}>
      <Grid item container justify='center'alignItems='center' spacing={1}>
        <Grid item xs={2} sm={2}>
          <Avatar label={usuario.nome} />
        </Grid>
        <Grid item container xs={10} sm={10}>
          <Grid item xs={12} component={Typography} className={classes.wihteText} >
            {usuario.nome}
          </Grid>
          <Grid item xs={12} component={Typography} className={classes.wihteText} variant='caption'>
              Código: {usuario.id}
          </Grid>
        </Grid>
        {
          usuario.cliente && (
            <Grid item xs={12}>
              <Typography className={classes.wihteText} variant='caption'>
                Instituição:
              </Typography>
              <Typography component='p' className={classes.wihteText} variant='caption'>
                {usuario.cliente.nome}
              </Typography>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  )
}

export default graphql(GET_USUARIO_LOGADO, { name: 'usuario' })(Profile)
