import React from "react";
import DeltaPie from "../DeltaPie";

const PieTipoEnvio = ({ notas, ...props }) => {
  const data = [
    {
      name: "Vencimento",
      value: notas.filter(
        (nota) =>
          nota.tipoEnvioEmpresa.toString().toLowerCase() === "vencimento"
      ).length,
    },
    {
      name: "Recebimento",
      value: notas.filter(
        (nota) =>
          nota.tipoEnvioEmpresa.toString().toLowerCase() !== "vencimento"
      ).length,
    },
  ];

  return <DeltaPie data={data} header="Clientes por tipo de envio:" />;
};

export default PieTipoEnvio;
