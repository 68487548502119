import gql from 'graphql-tag'

const empresaFragment = gql`
  fragment DadosEmpresa on Empresa {
    id
    idExterno
    plataforma
    cnpj
    razaoSocial
    nomeFantasia
    telefone
    email
    descricaoServico
    tipoEnvio
    inscricaoMunicipal
    inscricaoEstadual
    regimeEspecialTributacao
    codigoServicoMunicipal
    codigoItemServico
    codigoCNAE
    nfSequencial
    nfSerie
    nfLote
    issRetido
    simplesNacional
    incentivadorCultural
    enviarNfEmail
    usuario
    senha
    token
    homologado
    plataformaId
    endereco {
      cep
      pais
      estado
      cidade
      codigoIbgeCidade
      bairro
      logradouro
      numero
      complemento
    }
    aliquotas {
      ir
      csll
      iss
      pis
      cofins
      inss
    }
  }
`

export const LISTAR_EMPRESAS = gql`
  query ListaEmpresas {
    listarEmpresas {
      ...DadosEmpresa
    }
  }
  ${empresaFragment}
`

export const UPDATE_EMPRESA = gql`
  mutation AdicionarEmpresa($empresa: EmpresaInput) {
    adicionarEmpresa(empresa: $empresa) {
      ...DadosEmpresa
    }
  }
  ${empresaFragment}
`

export const CONSULTAR_PREFEITURA = gql`
  query ConsultaCaracteristicasPrefeitura($codigoIbge: Int!) {
    consultaCaracteristicasPrefeitura(codigoIbge: $codigoIbge) {
      tipoAutenticacao
      assinaturaDigital
      campoLoginProvedor
      suportaCancelamento
      usaAEDF
      usaRegimeEspecialTributacao
      usaCodigoServicoMunicipal
      usaDescricaoServico
      usaCNAE
      usaItemListaServico
      regimesEspecialTributacao {
        nome
        codigo
      }
    }
  }
`

export const ENVIAR_NOTA_TESTE = gql`
  mutation EnviarNotaTeste($empresaId: Int!) {
    enviarNotaTeste(empresaId: $empresaId)
  }
`
