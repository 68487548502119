/**
 * @description Generate hexadecimal color by string.
 * @param {String} str - The string to convert a hexadecimal color.
 * @returns String hexadecimal color
 */
export const stringToColor = (str = null) => {
  if (!str) return null

  let hash = 0
  let i

  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  let colour = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    colour += `00${value.toString(16)}`.substr(-2)
  }
  return colour
}

/**
 * @description Split a string by whitespace and returns the first letter of each word,
 * ignoring: da, de, do, das dos
 * @param {String} str - String to split
 * @param {Number} max - Max number initials
 */
export const getInitials = (str = null, max = 2) => {
  if (!str) return null

  let tmp = str
    .split(' ')
    .filter(f => f && !['da', 'de', 'do', 'das', 'dos'].includes(f.trim()))

  let initials = ''
  for (let i = 0; i < max; i++) {
    if (!tmp[i]) continue
    initials += tmp[i][0]
  }
  return initials.toUpperCase()
}
