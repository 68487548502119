import React from 'react'

import { Grid, TextField, Typography, Button } from '@material-ui/core'

export default ({ tipoAutenticacao, inputProps, setFieldValue, certificado, error }) => {
  if (tipoAutenticacao === 'USUARIO_E_SENHA') {
    return (
      <>
        <Grid item xs={12}>
          <TextField label='Usuário' {...inputProps('usuario')} />
        </Grid>
        <Grid item xs={12}>
          <TextField label='Senha' type='password' {...inputProps('senha')} />
        </Grid>
      </>
    )
  }
  if (tipoAutenticacao === 'TOKEN') {
    return (
      <Grid item xs={12}>
        <TextField label='Token' {...inputProps('token')} />
      </Grid>
    )
  }
  if (tipoAutenticacao === 'CERTIFICADO') {
    return (
      <>
        <Grid item xs={12}>
          <input type='file' style={{ display: 'none' }} id='certificado' name='certificado' onChange={(e) => setFieldValue('certificado', e.target.files[0])} />
          <label htmlFor='certificado'>
            <Button variant='contained' color='secondary' component='span'>
              {certificado ? certificado.name : 'Enviar Certificado'}
            </Button>
          </label>
        </Grid>
        {
          error && (
            <Grid item xs={12} component={Typography} color='secondary' variant='caption'>
              {error}
            </Grid>
          )
        }
        <Grid item xs={12}>
          <TextField label='Senha do Certificado' type='password' {...inputProps('senha')} />
        </Grid>
      </>
    )
  }
  return null
}
