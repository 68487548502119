import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, CircularProgress, Tooltip } from '@material-ui/core'

import RefreshIcon from '@material-ui/icons/Refresh'

const RefreshButton = ({ title, ariaLabel, loading, onClick }) => (
  <Tooltip title={title}>
    <IconButton aria-label={ariaLabel || title} onClick={onClick} disabled={loading}>
      {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
    </IconButton >
  </Tooltip>
)

RefreshButton.defaultProps = {
  title: 'Carregar dados',
  ariaLabel: null,
  loading: false
}

RefreshButton.propTypes = {
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default RefreshButton
