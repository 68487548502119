import React from "react";
import DeltaPie from "../DeltaPie";

const PieStatusNotas = ({ notas, ...props }) => {
  const data = [
    {
      name: "Autorizadas",
      value: notas
        .map((nota) => Number(nota.qtdNotasStatusAutorizadaNoMesDeCompetencia))
        .reduce((prev, cur) => prev + cur),
    },
    {
      name: "Negadas",
      value: notas
        .map((nota) => Number(nota.qtdNotasStatusNegadasNoMesDeCompetencia))
        .reduce((prev, cur) => prev + cur),
      warn: true,
    },
  ];

  return <DeltaPie data={data} header="Notas por status:" />;
};

export default PieStatusNotas;
