import gql from 'graphql-tag'

// Queries
export const GET_USUARIO_LOGADO = gql`
  {
    usuarioLogado {
      id
      nome
      tipo
      cliente {
        id
        codigo
        nome
      }
    }
  }
`

// Mutations
export const LOGIN_INTERNO = gql`
  mutation LoginInterno($usuario: String!, $senha: String!) {
    loginInterno(usuario: $usuario, senha: $senha)
  }
`

export const LOGIN_CLIENTE = gql`
  mutation LoginCliente($usuario: String!, $senha: String!, $clienteId: Int!) {
    loginCliente(usuario: $usuario, senha: $senha, clienteId: $clienteId)
  }
`
