import React from 'react'

import { TextField, Grid, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core'

export default ({ inputProps, prefeitura, checkboxProps }) => (
  <>
    <Grid item xs={12}>
      <TextField label='Tipo de Envio' name='tipoEnvio' select {...inputProps('tipoEnvio')}>
        <MenuItem value='RECEBIMENTO'>Recebimento</MenuItem>
        <MenuItem value='VENCIMENTO'>Vencimento</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Inscrição Municipal' {...inputProps('inscricaoMunicipal')} />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField label='Inscrição Estadual' {...inputProps('inscricaoEstadual')} />
    </Grid>
    <Grid item xs={12}>
      <TextField label='Regime Especial de Tributação' name='regimeEspecial' select {...inputProps('regimeEspecialTributacao')} disabled={!prefeitura.consultaCaracteristicasPrefeitura.usaRegimeEspecialTributacao}>
        {prefeitura.loading && <MenuItem value='0'><em>Não Disponível</em></MenuItem>}
        {!prefeitura.loading && prefeitura.consultaCaracteristicasPrefeitura.regimesEspecialTributacao ? (
          prefeitura.consultaCaracteristicasPrefeitura.regimesEspecialTributacao.map(r => <MenuItem key={r.codigo} value={r.codigo}>{r.nome}</MenuItem>)
        ) : null}
      </TextField>
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField label='Código de Serviço Municipal' {...inputProps('codigoServicoMunicipal')} disabled={!prefeitura.consultaCaracteristicasPrefeitura.usaCodigoServicoMunicipal} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField label='Código do Item do Serviço' {...inputProps('codigoItemServico')} disabled={!prefeitura.consultaCaracteristicasPrefeitura.usaItemListaServico} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField label='Código CNAE' {...inputProps('codigoCNAE')} disabled={!prefeitura.consultaCaracteristicasPrefeitura.usaCNAE} />
    </Grid>

    <Grid item xs={12} md={4}>
      <TextField label='Número Sequencial' {...inputProps('nfSequencial')} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField label='Série' {...inputProps('nfSerie')} />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField label='Lote' {...inputProps('nfLote')} />
    </Grid>
    <Grid item xs={12}>
      <FormControlLabel label='ISS Retido' control={<Checkbox value='1' {...checkboxProps('issRetido')} />} />
      <FormControlLabel label='Simples Nacional' control={<Checkbox value='1' {...checkboxProps('simplesNacional')} />} />
      <FormControlLabel label='Incentivador Cultural' control={<Checkbox value='1' {...checkboxProps('incentivadorCultural')} />} />
      <FormControlLabel label='Enviar por Email' control={<Checkbox value='1' {...checkboxProps('enviarNfEmail')} />} />
    </Grid>
  </>
)
