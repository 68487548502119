import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SearchIcon from '@material-ui/icons/Search'
// import BarChartIcon from '@material-ui/icons/BarChart'
// import SendIcon from '@material-ui/icons/Send'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'

import Dashboard from './Containers/Dashboard'
import Config from './Containers/Config'
import Consulta from './Containers/Consulta'
import DashboardInterno from './Containers/DashboardInterno'
import FormCliente from './Containers/FormCliente'
import ListarClientes from './Containers/ListaClientes'

const routesInternal = [
  {
    title: 'Início',
    icon: <DashboardIcon />,
    component: DashboardInterno,
    path: '/interno'
  },
  {
    title: 'Clientes',
    icon: <ListIcon />,
    component: ListarClientes,
    path: '/interno/clientes/'
  },
  {
    title: 'Adicionar',
    icon: <AddIcon />,
    component: FormCliente,
    path: '/interno/clientes/adicionar'
  },
  {
    component: FormCliente,
    path: '/interno/clientes/editar/:codigo'
  }
]

const routes = [
  {
    title: 'Início',
    icon: <DashboardIcon />,
    component: Dashboard,
    path: '/'
  },
  {
    title: 'Consulta',
    icon: <SearchIcon />,
    component: Consulta,
    path: '/consulta',
    divider: true
  },
  {
    title: 'Configurações',
    icon: <SettingsIcon />,
    component: Config,
    path: '/config'
  }
]

export {
  routesInternal,
  routes
}
