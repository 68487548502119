/* global fetch */
import React from "react";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { orderBy } from "lodash";

import { withSnackbar, useSnackbar } from "notistack";
import { withFormik } from "formik";
import {
  Grid,
  Paper,
  makeStyles,
  Table,
  TableRow as TableRowMUi,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SendIcon from "@material-ui/icons/Send";
import NoSimIcon from "@material-ui/icons/NoSim";

import moment from "moment";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import { REENVIAR_NOTA } from "Graphql/reenvio";
import { CANCELAR_NOTA } from "Graphql/notas";

import TableHeader from "./Components/TableHeader";
import TableRow from "./Components/TableRow";
import TableFooter from "./Components/TableFooter";
import Modal from "./Components/Modal";
import Filter from "./Components/Filter";
import { LISTAR_EMPRESAS } from "Graphql/empresa";
import ProgressButton from "Components/Buttons/ProgressButton";

const useStyles = makeStyles((theme) => ({
  title: { flex: "auto" },
  root: {
    padding: "10px",
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const perPages = [30, 50, 100];

const secureLink = (link) => {
  if (window.location.protocol === "https:") {
    return link.replace("http://", "https://");
  }
  return link;
};

const Consulta = ({
  values,
  consulta,
  reenviar,
  empresas,
  setFieldValue,
  ...props
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(perPages[0]);
  const [openFilter, setFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [notasSelecionadas, setNotasSelecionadas] = React.useState([]);
  const [
    dialogCancelarNotasAberto,
    setDialogCancelarNotasAberto,
  ] = React.useState(false);

  const hasError = consulta.error !== undefined;
  React.useEffect(() => {
    if (consulta.error) {
      props.enqueueSnackbar(consulta.error.message, { variant: "error" });
    }
  }, [hasError, consulta, props]);

  if (!consulta.listarNotas) consulta.listarNotas = [];

  consulta.listarNotas = consulta.listarNotas
    .filter((f) => {
      if (values.cliente) {
        return (
          (f.aluno &&
            values.cliente !== "" &&
            f.aluno.nome !== "" &&
            f.aluno.nome
              .toLocaleUpperCase()
              .includes(values.cliente.toLocaleUpperCase())) ||
          (f.tomador &&
            values.cliente !== "" &&
            f.tomador.nome !== "" &&
            f.tomador.nome
              .toLocaleUpperCase()
              .includes(values.cliente.toLocaleUpperCase()))
        );
      } else return true;
    })
    .filter((f) => {
      if (values.empresa) {
        return f.EmpresaId === parseInt(values.empresa, 10);
      } else return true;
    })
    .filter((f) => {
      if (values.status) {
        return f.status === values.status;
      } else return true;
    });

  if (values.cliente !== "") {
    consulta.listarNotas = orderBy(consulta.listarNotas, ["tomador.nome"]);
  }

  const notasFiltradas = consulta.listarNotas.slice(
    page * perPage,
    page * perPage + perPage
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCancelarSelecionadas = async () => {
    setDialogCancelarNotasAberto(true);
  };

  const handleCloseCancelarNotas = async (cancelar) => {
    setDialogCancelarNotasAberto(false);

    if (!cancelar) {
      return;
    }

    setLoading(true);
    let notasCanceladas = [];
    let notasComErro = [];
    try {
      for (let i = 0; i < notasSelecionadas.length; i++) {
        const notaId = notasSelecionadas[i];

        const ret = await props.cancelar({
          variables: {
            id: notaId,
          },
        });

        if (ret.data.cancelarNota) {
          notasCanceladas.push(notaId);
        } else {
          notasComErro.push(notaId);
        }
      }
      if (notasCanceladas.length === 0 && notasComErro.length === 0) {
        enqueueSnackbar("Nenhuma nota cancelada", {
          variant: "info",
        });
      } else if (notasCanceladas.length > 0 && notasComErro.length === 0) {
        enqueueSnackbar(
          `${notasCanceladas.length} Nota(s) cancelada(s) com sucesso."`,
          {
            variant: "success",
          }
        );
      } else if (notasCanceladas.length > 0 && notasComErro.length > 0) {
        enqueueSnackbar(
          `${notasCanceladas.length} Nota(s) cancelada(s) com sucesso. Não foi possível cancelar ${notasComErro.length} nota(s)"`,
          {
            variant: "warning",
          }
        );
      } else {
        enqueueSnackbar("Não foi possí­vel cancelar a(s) nota selecionada(s)", {
          variant: "error",
        });
      }
      setNotasSelecionadas([]);
    } catch (error) {
      props.enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  function selecionarNota(nota) {
    let arrayNotas = [...notasSelecionadas];

    if (arrayNotas.indexOf(nota.id) === -1) {
      arrayNotas.push(nota.id);
    } else {
      arrayNotas = arrayNotas.filter((item) => item !== nota.id);
    }
    setNotasSelecionadas(arrayNotas);
  }

  function limparNotasSelecionadas() {
    setNotasSelecionadas([]);
  }

  async function download(tipo) {
    setLoading(true);
    handleClose();

    try {
      const zip = new JSZip();

      for (let i = 0; i < consulta.listarNotas.length; i++) {
        const nota = consulta.listarNotas[i];
        const link = tipo === "xml" ? nota.linkXML : nota.linkPDF;
        const notas = await fetch(secureLink(link));
        const arquivo = await notas.blob();
        zip.file(`${nota.idExterno}.${tipo.toLowerCase()}`, arquivo);
      }

      const zippado = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
        compression: "DEFLATE",
        compressionOptions: {
          level: 1,
        },
      });

      FileSaver.saveAs(zippado, "Notas.zip");
    } catch (error) {
      props.enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  const handleClickReenviar = async () => {
    handleClose();
    const envio = await reenviar();

    if (envio.data.reenviarNotas) {
      enqueueSnackbar("Notas reenviadas com sucesso!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Não foi possível reenviar as notas selecionadas", {
        variant: "error",
      });
    }
  };

  return (
    <>
      {openFilter && (
        <Modal open={openFilter} handleClose={() => setFilter(false)}>
          <Filter
            dataIni={values.dataInicio}
            dataFim={values.dataFim}
            empresas={empresas.listarEmpresas || []}
            empresa={values.empresa}
            cliente={values.cliente}
            status={values.status}
            setDataIni={(data) =>
              setFieldValue("dataInicio", data) && setPage(0)
            }
            setDataFim={(data) => setFieldValue("dataFim", data) && setPage(0)}
            setEmpresa={({ target }) =>
              setFieldValue("empresa", target.value) && setPage(0)
            }
            setStatus={({ target }) =>
              setFieldValue("status", target.value) && setPage(0)
            }
            setCliente={({ target }) => {
              setPage(0);
              setFieldValue("cliente", target.value);
            }}
          />
        </Modal>
      )}
      <Grid container component={Paper} className={classes.root}>
        <Grid item xs={12}>
          {(consulta.loading || loading) && <LinearProgress />}
          <div className={classes.tableWrapper}>
            <Toolbar component={Grid} container justify="flex-end">
              <Grid item className={classes.title}>
                <Typography variant="h6">Consulta</Typography>
              </Grid>
              <Grid item>
                {consulta.listarNotas.length > 0 && !loading && (
                  <TablePagination
                    labelRowsPerPage="Registros por página"
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}-${to} de ${count}`
                    }
                    rowsPerPageOptions={perPages}
                    component="div"
                    count={consulta.listarNotas.length}
                    rowsPerPage={perPage}
                    page={page}
                    backIconButtonProps={{ "aria-label": "Página anterior" }}
                    nextIconButtonProps={{ "aria-label": "Próxima página" }}
                    onChangePage={(_, page) => setPage(page)}
                    onChangeRowsPerPage={({ target }) =>
                      setPerPage(target.value)
                    }
                  />
                )}
              </Grid>

              <Grid item>
                <Tooltip title="Filtrar">
                  <div>
                    <IconButton
                      aria-label="Filtrar"
                      onClick={() => setFilter(true)}
                      disabled={loading === true}
                    >
                      <FilterListIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>

              <Grid item>
                <ProgressButton
                  loading={loading}
                  color="default"
                  onClick={handleClick}
                  label="Baixar em Lote"
                  icon={CloudDownloadIcon}
                  disabled={notasSelecionadas.length > 0}
                />
                <Menu
                  id="fade-menu"
                  anchorEl={anchorEl}
                  disabled={notasSelecionadas.length > 0}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem
                    disabled={notasSelecionadas.length > 0}
                    onClick={() => download("pdf")}
                  >
                    PDF
                  </MenuItem>
                  <MenuItem
                    disabled={notasSelecionadas.length > 0}
                    onClick={() => download("xml")}
                  >
                    XML
                  </MenuItem>
                </Menu>
              </Grid>

              <Grid item>
                <Tooltip title="Reenviar Notas">
                  <IconButton
                    aria-label="Reenviar Notas"
                    onClick={() => handleClickReenviar()}
                    disabled={notasSelecionadas.length > 0}
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item>
                <Tooltip title="Cancelar Notas Selecionadas">
                  <div>
                    <ProgressButton
                      loading={loading}
                      color="default"
                      onClick={() => handleClickCancelarSelecionadas()}
                      label="Cancelar Notas Selecionadas"
                      icon={NoSimIcon}
                      disabled={notasSelecionadas.length === 0}
                      aria-label="Cancelar Notas Selecionadas"
                    />
                  </div>
                </Tooltip>
                <Dialog
                  open={dialogCancelarNotasAberto}
                  onClose={() => handleCloseCancelarNotas(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Cancelar notas em lote
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {`Continuando com este procedimento, você irá cancelar ${notasSelecionadas.length} Nota(s). Essa ação é irreversível. Deseja prosseguir?`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleCloseCancelarNotas(false)}
                      color="primary"
                    >
                      Não
                    </Button>
                    <Button
                      onClick={() => handleCloseCancelarNotas(true)}
                      color="secondary"
                    >
                      Sim, cancelar as notas selecionadas
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Toolbar>
            <Table className={classes.table}>
              <TableHeader
                temNotasSelecionadas={notasSelecionadas.length > 0}
                limparNotasSelecionadas={() => limparNotasSelecionadas()}
              />
              <TableBody>
                {consulta.listarNotas.length === 0 && (
                  <TableRowMUi>
                    <TableCell colSpan={6}>Nenhuma nota</TableCell>
                  </TableRowMUi>
                )}
                {notasFiltradas.map((nota, i) => (
                  <TableRow
                    key={i}
                    nota={nota}
                    selecionarNota={() => selecionarNota(nota)}
                    notaSelecionada={notasSelecionadas.indexOf(nota.id) > -1}
                    loading={loading}
                  />
                ))}
                {consulta.listarNotas.length > 0 && (
                  <TableFooter notas={consulta.listarNotas} />
                )}
              </TableBody>
            </Table>
            {consulta.listarNotas.length > 0 && !loading && (
              <TablePagination
                labelRowsPerPage="Registros por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                rowsPerPageOptions={perPages}
                component="div"
                count={consulta.listarNotas.length}
                rowsPerPage={perPage}
                page={page}
                backIconButtonProps={{ "aria-label": "Página anterior" }}
                nextIconButtonProps={{ "aria-label": "Próxima página" }}
                onChangePage={(_, page) => setPage(page)}
                onChangeRowsPerPage={({ target }) => setPerPage(target.value)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default compose(
  withSnackbar,
  withFormik({
    mapPropsToValues: () => ({
      dataInicio: moment().startOf("month"),
      dataFim: moment().endOf("month"),
      empresa: "",
      cliente: "",
      status: "",
    }),
  }),
  graphql(REENVIAR_NOTA, { name: "reenviar" }),
  graphql(LISTAR_EMPRESAS, { name: "empresas" }),
  graphql(CANCELAR_NOTA, { name: "cancelar" }),
  graphql(
    gql`
      query ConsultarNotas($filtro: InputNotaFiltro) {
        listarNotas(filtro: $filtro) {
          id
          idExterno
          linkPDF
          linkXML
          aluno {
            nome
          }
          parcela
          tomador {
            nome
          }
          dataCompetencia
          emitidoEm
          status
          motivoStatus
          valor
          valorReferencia
          aliquotas {
            valorTotal
          }
          EmpresaId
        }
      }
    `,
    {
      name: "consulta",
      options: ({ values }) => ({
        variables: {
          filtro: {
            dataInicio: moment(values.dataInicio).isValid()
              ? moment(values.dataInicio).format("YYYY-MM-DD")
              : undefined,
            dataFim: moment(values.dataFim).isValid()
              ? values.dataFim.format("YYYY-MM-DD")
              : undefined,
          },
        },
      }),
    }
  )
)(Consulta);
