import React from 'react'
import PropTypes from 'prop-types'

import { Grid, TextField, MenuItem } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

const statusList = [{
  id: '', name: 'Todos os Status'
}, {
  id: 'ENVIANDO', name: 'Enviando'
}, {
  id: 'AUTORIZADA', name: 'Autorizada'
}, {
  id: 'NEGADA', name: 'Negada'
}, {
  id: 'DENEGADA', name: 'Denegada'
}, {
  id: 'CANCELAMENTO NEGADO', name: 'Cancelamento Negado'
}]

function Filter ({
  dataIni, dataFim, setDataIni, setDataFim, empresas, empresa, setEmpresa,
  setCliente, cliente, status, setStatus, ...props
}) {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          autoOk
          variant='inline'
          inputVariant='outlined'
          margin='normal'
          value={dataIni}
          format='DD/MM/YYYY'
          onChange={setDataIni}
          placeholder='Data Inicial'
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <KeyboardDatePicker
          autoOk
          variant='inline'
          inputVariant='outlined'
          margin='normal'
          value={dataFim}
          format='DD/MM/YYYY'
          onChange={setDataFim}
          placeholder='Data Final'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin='normal'
          variant='outlined'
          placeholder='Procurar por cliente'
          value={cliente}
          onChange={setCliente}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField margin='normal' variant='outlined' select SelectProps={{ displayEmpty: true }} value={empresa} onChange={setEmpresa}>
          <MenuItem value=''><em>Todas Empresas</em></MenuItem>
          {empresas.map(empresa => <MenuItem key={empresa.id} value={empresa.id}>{`${empresa.cnpj} - ${empresa.razaoSocial}`}</MenuItem>)}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField margin='normal' variant='outlined' select SelectProps={{ displayEmpty: true }} value={status} onChange={setStatus}>
          {statusList.map(status => <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>)}
        </TextField>
      </Grid>
    </Grid>
  )
}
Filter.defaultProps = {
  empresas: []
}
Filter.propTypes = {
  dataIni: PropTypes.object.isRequired,
  dataFim: PropTypes.object.isRequired,
  setDataIni: PropTypes.func.isRequired,
  setDataFim: PropTypes.func.isRequired,
  setEmpresa: PropTypes.func.isRequired,
  setCliente: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  empresa: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  cliente: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  empresas: PropTypes.arrayOf(PropTypes.shape({
    idExterno: PropTypes.string.isRequired,
    cnpj: PropTypes.string.isRequired,
    razaoSocial: PropTypes.string.isRequired
  }))
}

export default Filter
