import gql from 'graphql-tag'

// Fragments
export const FRAGMENT_CLIENTE = gql`
  fragment ClienteFieldsFragment on Cliente {
    id
    codigo
    nome
    alias
  }
`

// Queries
export const LISTAR_CLIENTE = gql`
  query ListarCliente ($codigo: Int, $alias: String) {
    listarCliente (codigo: $codigo, alias: $alias) {
      ...ClienteFieldsFragment
    }
  }
  ${FRAGMENT_CLIENTE}
`

export const GET_CLIENTE = gql`
  query ListarCliente ($codigo: Int) {
    listarCliente (codigo: $codigo) {
      ...ClienteFieldsFragment
      apiConsulta
      apiEscrita
      apiEmpresas
      apiLogin
    }
  }
  ${FRAGMENT_CLIENTE}
`

export const LISTAR_CLIENTES = gql`
  query ListarClientes {
    listarClientes {
      ...ClienteFieldsFragment
    }
  }
  ${FRAGMENT_CLIENTE}
`

export const VERIFICA_TOKEN = gql`
  query ClienteVerificaToken {
    clienteVerificaToken
  }
`

// Mutations
export const REMOVER_CLIENTE = gql`
  mutation RemoverCliente ($codigo: Int!) {
    removerCliente(codigo: $codigo)
  }
`

export const UPSERT_CLIENTE = gql`
  mutation AdicionarCliente($cliente: ClienteInput) {
    adicionarCliente(cliente: $cliente) {
      ...ClienteFieldsFragment
      apiConsulta
      apiEscrita
      apiEmpresas
      apiLogin
    }
  }
  ${FRAGMENT_CLIENTE}
`

// Subscriptions
export const SUBSCRIBE_CLIENTES = gql`
  subscription ClienteSubscription {
    clienteSubscription {
      action
      cliente {
        ...ClienteFieldsFragment
      }
    }
  }
  ${FRAGMENT_CLIENTE}
`
