import React from "react";
import DeltaPie from "../DeltaPie";

const PiePlataforma = ({ notas, ...props }) => {
  const data = [
    {
      name: "E-Notas",
      value: notas.filter(
        (nota) => nota.plataformaEmpresa.toString().toLowerCase() === "enotas"
      ).length,
    },
    {
      name: "Desativado",
      value: notas.filter(
        (nota) => nota.plataformaEmpresa.toString().toLowerCase() !== "enotas"
      ).length,
      warn: true,
    },
  ];

  return <DeltaPie data={data} header="Clientes por plataforma:" />;
};

export default PiePlataforma;
