import React from 'react'
import { ListItem, ListItemText } from '@material-ui/core'

const NenhumItem = () => (
  <ListItem>
    <ListItemText primary='Nenhum cliente adicionado!' />
  </ListItem>
)

export default NenhumItem
